import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";

import FullSidebarMenu from './../components/full_sidebar_menu';
import BrokerCard from '../components/broker_card';

import { 
    EuiPage,
    EuiTitle,
    EuiPageContent,
    EuiSpacer,
    EuiPageSideBar,
    EuiPanel,
    EuiText,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageBody
   } from '@elastic/eui';

import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";


const Sitemap = () => {

    const [brokersRender, setBrokersRender] = React.useState([]);

    const GET_DATA = graphql`{
        allContentfulBroker {
          edges {
            node {
              id
              name
              slug
              updatedAt
            }
          }
        }
      }  `;
        
    const { allContentfulBroker: { edges: brokers } } = useStaticQuery(GET_DATA); 

    React.useEffect(() => {
        setBrokersRender( 
            brokers.map((element) => {
                return <li style={{ margin: 10, listStyleType: 'disc', listStylePosition: 'inside'}} key={element.node.slug}>
                        <Link to={`/${element.node.slug}/`}>{element.node.name}</Link>
                       </li>
            }) 
        )
    })
   
    return <Layout>
            <SEO 
              title="Карта сайта"
              canonical="/sitemap/"
            />
            
            <EuiPage restrictWidth={1230} >
    <EuiPageSideBar style={{ minWidth: 270 }}>

    <EuiPanel className={`sticky`}>
          <EuiText><h3>Все категории:</h3></EuiText>
            <EuiSpacer size="s" />
            <FullSidebarMenu />
          </EuiPanel>  
    </EuiPageSideBar>
    
    <EuiPageBody>
      <EuiPageHeader>
        <EuiTitle size="l">
              <h1>Карта сайта:</h1>
            </EuiTitle>
        <EuiSpacer />   
       </EuiPageHeader>
       
      <EuiPageContent>    
        <EuiPageContentBody>
        <EuiText><h2>Обзоры брокеров:</h2></EuiText>
        <EuiSpacer />
          <ul>{brokersRender}</ul>
        </EuiPageContentBody>
      </EuiPageContent>
    </EuiPageBody>
    
    </EuiPage>
        </Layout>
}

export default Sitemap;